var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Add Taxonomy Organisation" }
      }),
      _c(
        "gov-back-link",
        { attrs: { to: { name: "admin-index-taxonomies-organisations" } } },
        [_vm._v("Back to taxonomy organisations")]
      ),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "one-half" } },
                [
                  _c(
                    "gov-heading",
                    { attrs: { size: "xl" } },
                    [
                      _c("gov-caption", { attrs: { size: "xl" } }, [
                        _vm._v("Taxonomies")
                      ]),
                      _vm._v("\n          Organisations\n        ")
                    ],
                    1
                  ),
                  _c("gov-heading", { attrs: { size: "m" } }, [
                    _vm._v("Add organisation")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "\n          From this page, you can add the names of the Organisations referrers\n          can select from when completing a referral.\n        "
                    )
                  ]),
                  _c("taxonomy-form", {
                    attrs: {
                      errors: _vm.form.$errors,
                      name: _vm.form.name,
                      order: _vm.form.order
                    },
                    on: {
                      "update:name": function($event) {
                        return _vm.$set(_vm.form, "name", $event)
                      },
                      "update:order": function($event) {
                        return _vm.$set(_vm.form, "order", $event)
                      },
                      clear: function($event) {
                        return _vm.form.$errors.clear($event)
                      }
                    }
                  }),
                  _vm.form.$submitting
                    ? _c(
                        "gov-button",
                        { attrs: { disabled: "", type: "submit" } },
                        [_vm._v("Creating...")]
                      )
                    : _c(
                        "gov-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("Create")]
                      ),
                  _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }