var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.name,
          id: "name",
          label: "Name",
          type: "text",
          error: _vm.errors.get("name")
        },
        on: {
          input: function($event) {
            return _vm.onInput("name", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }